module.exports = [{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"lg4Kds1ca4h8LpWigCJtUvPhKXvmdftr","devKey":"lg4Kds1ca4h8LpWigCJtUvPhKXvmdftr","trackPage":false,"delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.watchhabitat.com"},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Watch Habitat","short_name":"Watch Habitat","description":"A premium subscription lifestyle Streaming and Ecommerce platform.","start_url":"/","background_color":"black","theme_color":"black","display":"standalone","icon":"./src/data/brands/habitat/styles/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"31337efd103b24fd9e7427dcf40dc08f"},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"cleanupOutdatedCaches":true,"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}}]}},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/builds/watchhabitat/habitat/packages/ui/src/cms/cms.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
