// extracted by mini-css-extract-plugin
export var logo = "styles-module--logo--ijG0m";
export var landingGreeting = "styles-module--landingGreeting---XThF";
export var habitatVideoContainer = "styles-module--habitatVideoContainer--8rvDY";
export var habitatVideo = "styles-module--habitatVideo--1hACY";
export var containerLanding = "styles-module--containerLanding--2VJ2D";
export var title = "styles-module--title--r29Kb";
export var landingSubtitle = "styles-module--landingSubtitle--1pmaF";
export var buttonFreeTrial = "styles-module--buttonFreeTrial--2COHW";
export var landingWrapper = "styles-module--landingWrapper--371HU";
export var appInfoSection = "styles-module--appInfoSection--1392S";
export var appInfo = "styles-module--appInfo--qzVCe";
export var appInfoHeader = "styles-module--appInfoHeader--1jr5-";
export var appInfoSubHeader = "styles-module--appInfoSubHeader--1onNo";
export var productInfo = "styles-module--productInfo--erZZG";
export var productInfoHeader = "styles-module--productInfoHeader--1a3W6";
export var productInfoSubHeader = "styles-module--productInfoSubHeader--3RNvo";
export var productImageContentContainer = "styles-module--productImageContentContainer--2hJUP";
export var productImageTextContainer = "styles-module--productImageTextContainer--1DLZw";
export var productImageTextGroup = "styles-module--productImageTextGroup--2YxlC";
export var productImageNumbers = "styles-module--productImageNumbers--1MEI8";
export var productImageText = "styles-module--productImageText--3MKFL";
export var productVideoContainer = "styles-module--productVideoContainer--3K-_M";
export var productVideo = "styles-module--productVideo--3nE6c";
export var watchShopImage = "styles-module--watchShopImage--2XsYa";
export var appImage = "styles-module--appImage--W7vLP";