/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import { NavStateContext } from '@lib/navContext';
import IndexLayout from '@layouts/index';
import { Img } from '@components/Img';
import { Button } from '@components/Buttons';
import Background from '@src/components/Background';
import { CmsCollectionFile } from 'netlify-cms-core';
import { defaultFields, IPageProps } from '@src/cms/cms-types';
import { ClientLogger } from '@src/lib/ClientLogger';
import { User } from '@apiClient/gql-types';
import { MeStateContext } from '@lib/meContext';
import { BrandUtil } from '@sharedLib/index';
import { usePageImage } from '@src/lib/usePageImage';

import {
  containerLanding,
  landingWrapper,
  landingGreeting,
  landingSubtitle,
  buttonFreeTrial,
  appInfoSection,
  appInfo,
  appInfoHeader,
  appInfoSubHeader,
  productInfoHeader,
  productInfoSubHeader,
  productImageContentContainer,
  habitatVideo,
  habitatVideoContainer,
  productVideoContainer,
  productVideo,
  productInfo,
  appImage as appImageStyle,
  watchShopImage as watchShopImageStyle,
} from './styles.module.scss';

///
/// CMS model
///
export const LandingPageCMS: CmsCollectionFile = {
  file: `/packages/ui/src/data/brands/habitat/pages/LandingPage/landingPage.md`,
  label: 'Landing Page',
  name: 'landing_page',
  fields: [
    ...defaultFields('none', 'layout'),
    {
      label: 'Component Path',
      name: 'componentPath',
      widget: 'hidden',
      default: 'src/data/brands/habitat/pages/LandingPage/LandingPage.tsx',
    },
    {
      label: 'Slogan',
      name: 'slogan',
      widget: 'text',
      required: true,
    },
    {
      label: 'Greeting',
      name: 'greeting',
      widget: 'image',
    },
    {
      label: 'Free Trial Button Text',
      name: 'freeTrialButtonText',
      widget: 'string',
    },
    {
      label: 'App Image',
      name: 'appImage',
      widget: 'image',
    },
    {
      label: 'Watch Shop',
      name: 'watchShop',
      widget: 'image',
    },
  ],
};

interface Props {
  slogan: string;
  greeting: string;
  freeTrialButtonText: string;
  appImage: string;
  watchShop: string;
}

const SHOW_CONTINUE_WATCHING = false;
const DEBUG = false;

const LandingPage = (props: IPageProps<Props>) => {
  const pageData = props.pageContext.frontMatter;
  const greetingImage = usePageImage(pageData.greeting);
  const watchShopImage = usePageImage(pageData.watchShop);
  const appImage = usePageImage(pageData.appImage);

  const nav = useContext(NavStateContext);
  const api = usePvepApi();
  const userState = api.state;
  const meContext = useContext(MeStateContext);
  const brandInfo = BrandUtil.getSiteInfo();

  const onClickFreeTrial = () => {
    nav.setRoot({ path: '/sign-up' });
  };

  useEffect(() => {
    if (api.isLoggedIn()) {
      nav.setRoot({ path: '/app', title: 'Home' });
    }
  }, [api.state]);

  useEffect(() => {
    const locationMessage = 'IndexPage.useEffect';
    DEBUG && ClientLogger.debug(locationMessage, 'useEffect', { userState, props });
    function checkSubscription(me: User) {
      if (me.accountStatus === 'active') {
        DEBUG && ClientLogger.debug(locationMessage, 'checkSubscription Navigating to /app');
        nav.setRoot({ path: '/app', title: 'Home' });
        if (SHOW_CONTINUE_WATCHING) {
          DEBUG && ClientLogger.debug(locationMessage, 'Convert code success. Get WatchingList');
          api
            .getWatchList(userState.email)
            .then(response => {
              DEBUG && ClientLogger.debug(locationMessage, `getWatchList Response = ${JSON.stringify(response)}`);
              nav.setRoot({ path: '/app', title: 'Home' });
            })
            .catch(error => {
              ClientLogger.error(locationMessage, 'getWatchList returns error', error);
              nav.setRoot({ path: '/app', title: 'Home' });
            });
        }
      } else {
        DEBUG && ClientLogger.debug(locationMessage, 'isLoggedIn = true. isSubscribed = false Navigating to /subscribe');
        nav.setRoot({ path: '/subscribe' });
      }
    }

    // Auto load the home screen if user is logged in  and brand  has  videoNeedsRegistration === false,
    if (userState.isLoggedIn && brandInfo.videoNeedsRegistration) {
      if (!meContext.me) {
        api
          .getMe()
          .then(meResp => {
            if (meResp.data?.me) {
              meContext.setMe(meResp.data.me);
              checkSubscription(meResp.data.me);
            } else {
              ClientLogger.error(locationMessage, `Expected to retrieve me userStart = ${JSON.stringify(userState)}`);
              nav.setRoot({ path: '/login', title: 'Login' });
            }
          })
          .catch(e => {
            ClientLogger.error(locationMessage, `getMe failed`, e);
            nav.setRoot({ path: '/login', title: 'Login' });
          });
      } else {
        checkSubscription(meContext.me);
      }
    }
  }, [userState, meContext.me]);

  DEBUG && ClientLogger.debug('LandingPage', 'render', DEBUG, { props });

  return (
    <IndexLayout noVideo>
      <Background />
      <div data-cy="landingContainer" className={containerLanding}>
        <div className={landingWrapper}>
          <div>
            <Img childImageSharp={greetingImage.childImageSharp} className={landingGreeting} />
            <p data-cy="slogan" className={landingSubtitle}>
              {pageData.slogan}
            </p>
            <Button dataCY="freeTrialButtonText" className={buttonFreeTrial} onClick={onClickFreeTrial}>
              {pageData.freeTrialButtonText}
            </Button>
          </div>
        </div>
      </div>
      <div className={appInfoSection}>
        <div className={appInfo}>
          <div className={appInfoHeader}>Shop from the shows you love.</div>
          <div className={appInfoSubHeader}>
            Habitat is a subscription service for lifestyle television that provides a built-in e-commerce experience for your convenience.
          </div>
        </div>
        <div className={productImageContentContainer}>
          <div className={`${habitatVideoContainer} ${productVideoContainer}`}>
            <video className={`${habitatVideo} ${productVideo}`} playsInline autoPlay muted loop>
              <source src="./AT_images/1.5-demo-vid.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div className={productInfo}>
          <Img childImageSharp={watchShopImage.childImageSharp} className={watchShopImageStyle} />
          <div className={productInfoHeader}>
            Get what you need, while you <br /> watch what you want.
          </div>
          <Img childImageSharp={appImage.childImageSharp} className={appImageStyle} />
          <div className={productInfoSubHeader}>
            Enjoy exceptional original series <br /> with their unique products at your fingertips.
          </div>
        </div>
      </div>
    </IndexLayout>
  );
};

export default LandingPage;
